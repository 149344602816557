<template>
    <div class="bg-blue-950 text-white h-screen overflow-y-auto">
      <div class="flex flex-col justify-between pb-20">
        <div class="p-6">
          <h1 class="text-3xl font-bold mb-4">PennyWhale Season 2 Whalepool Terms and Conditions</h1>
          <p class="mb-4">
            Welcome to PennyWhale's Season 2 Leaderboard Campaign! This document will detail the mechanics of the Whalepool. By participating in this event, you agree to the following terms and conditions:
          </p>
  
          <!-- Campaign Duration -->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Campaign Duration</h2>
            <ul class="list-disc pl-6">
              <li>The campaign will start on 21st October 2024 and will run until the 21st of November 2024 23:59:59 UTC.</li>
            </ul>
          </div>

          <!-- How to Play -->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Whalepool Participation</h2>
            <ul class="list-disc pl-6">
              <li>Purchase the Shell Packages or complete specific tasks to earn shells, which can be used to make guesses in the "Guess the Bitcoin (BTC) Price" game.</li>
            </ul>
          </div>
  
          <!-- Fair Play Shell Collection-->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Prize Pools and Reward Calculation</h2>
            <ul class="list-decimal pl-6 space-y-2">
              <li><strong>Fair Play:</strong> All participants will start with an equal opportunity to collect shells from the moment the contest begins. </li>
              <li><strong>Shell Validity:</strong> Only shells that are collected and used during the campaign period will be considered for ranking and rewards. </li>
              <li><strong>Shell Earning Opportunities:</strong> You can obtain shells by completing the following: completing daily tasks, following our social media accounts, and purchasing our Shell Packages. </li>
            </ul>
          </div>
  
          <!-- Ranking and Leaderboard -->
          <!-- <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Ranking and Leaderboard</h2>
            <ul class="list-decimal pl-6 space-y-2">
              <li><strong>Leaderboard:</strong> All player rankings will be displayed on the leaderboard, with rankings
                based on campaign points.</li>
              <li><strong>Campaign Points:</strong> Campaign points are defined as the total number of coins earned during
                the campaign.</li>
              <li><strong>Top 500 Visibility:</strong> Only participants who rank in the top 500 will have their positions
                shown on the leaderboard.</li>
              <li><strong>Rewards:</strong> The more whales (players) who join the game, the more rewards will be
                distributed.</li>
            </ul>
          </div> -->
  
          <!-- Prize Pool and Reward Calculation -->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Prize Pool and Reward Calculation</h2>
            <p class="mb-4"><strong>Prize Pool:</strong> will have a base prize pool starting at 3,000 USDT. For every Shell Package purchased, the majority of the purchased amount will be added to the Whalepool from the start of the campaign until the end of the campaign, making the Whalepool size unlimited. The Whalepool will thus be a chance for every whale to earn a MEGA REWARDS</p>
            <ul class="list-decimal pl-6 space-y-2">
              <!-- <li><strong>Reward Growth:</strong> For every 10,000 coins earned by all players, the prize pool will
                increase by 0.02 USDT.</li> -->
              <li><strong>Top 150 Players Rewarded:</strong> The top 150 players will receive USDT rewards based on their ranks at the end of the campaign.</li>
            </ul>
          </div>
  
          <!-- Violation Policy -->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Violation Policy</h2>
            <ul class="list-decimal pl-6 space-y-2">
              <li>Participants found to be engaging in fraudulent activity or violating the game’s fair play policies will be disqualified and forfeit any rewards.</li>
            </ul>
          </div>
  
          <!-- Prize Distribution -->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Prize Distribution</h2>
            <ul class="list-decimal pl-6 space-y-2">
              <li><strong>Prize Calculation:</strong> The final prize amounts will be calculated within 3 days after the campaign ends. </li>
              <li><strong>Violation Policy:</strong> Players who are found to have violated the rules will be disqualified and forfeit their prizes. </li>
              <li><strong>Prize Distribution Timeline:</strong> Once the prizes are confirmed, they will be distributed to the winners’ connected wallets within 7 to 14 days.</li>
              <li><strong>Wallet Requirement:</strong> Prizes will be sent only to the wallets connected to the players' Redotpay accounts.</li>
            </ul>
          </div>
  
          <!-- Prize Breakdown -->
          <div class="mb-6">
            <h2 class="text-2xl font-semibold mb-2">Prize Breakdown for Whalepool (Assuming Pool Size of 10,000 USDT)</h2>
            <ul class="list-disc pl-6 space-y-2">
              <li><strong>1st Prize:</strong> 3,000 USDT</li>
              <li><strong>2nd Prize:</strong> 1,300 USDT</li>
              <li><strong>3rd Prize:</strong> 600 USDT</li>
              <li><strong>4th Prize:</strong> 400 USDT</li>
              <li><strong>5th Prize:</strong> 300 USDT</li>
              <li><strong>6th-20th Prizes:</strong> 1,500 USDT total (100 USDT each)</li>
              <li><strong>21st-50th Prizes:</strong> 900 USDT total ( 30 USDT each)</li>
              <li><strong>51st-150th Prizes:</strong> 2,000 USDT total (20 USDT each)</li>
            </ul>
          </div>
  
          <!-- General Provisions -->
          <div>
            <h2 class="text-2xl font-semibold mb-2">General Provisions</h2>
            <ul class="list-disc pl-6 space-y-2">
              <li><strong>Amendments:</strong> PennyWhale reserves the right to amend these terms and conditions at any time.</li>
              <li><strong>Final Decisions:</strong> All decisions made by PennyWhale regarding the campaign are final.</li>
            </ul>
            <p class="mt-4">By participating, you acknowledge that you understand and agree to the terms and conditions
              stated above.</p>
            <p class="mt-4 font-semibold">Good luck, and may the best whale win!</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  