import { default as indexn5MEgUhIZQMeta } from "/home/runner/work/pennywhales-tma/pennywhales-tma/pages/index.vue?macro=true";
import { default as winnerlistMQCxg26WKzMeta } from "/home/runner/work/pennywhales-tma/pennywhales-tma/pages/pennywhalestma/admin/redotpay/winnerlist.vue?macro=true";
import { default as taskcheckavNo1G2njBMeta } from "/home/runner/work/pennywhales-tma/pennywhales-tma/pages/pennywhalestma/admin/taskprogress/taskcheck.vue?macro=true";
import { default as withdrawList4UPQdwVgp4Meta } from "/home/runner/work/pennywhales-tma/pennywhales-tma/pages/pennywhalestma/admin/withdraw/request/withdrawList.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/pennywhales-tma/pennywhales-tma/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pennywhalestma-admin-redotpay-winnerlist",
    path: "/pennywhalestma/admin/redotpay/winnerlist",
    component: () => import("/home/runner/work/pennywhales-tma/pennywhales-tma/pages/pennywhalestma/admin/redotpay/winnerlist.vue").then(m => m.default || m)
  },
  {
    name: "pennywhalestma-admin-taskprogress-taskcheck",
    path: "/pennywhalestma/admin/taskprogress/taskcheck",
    component: () => import("/home/runner/work/pennywhales-tma/pennywhales-tma/pages/pennywhalestma/admin/taskprogress/taskcheck.vue").then(m => m.default || m)
  },
  {
    name: "pennywhalestma-admin-withdraw-request-withdrawList",
    path: "/pennywhalestma/admin/withdraw/request/withdrawList",
    component: () => import("/home/runner/work/pennywhales-tma/pennywhales-tma/pages/pennywhalestma/admin/withdraw/request/withdrawList.vue").then(m => m.default || m)
  }
]