const useEncryption = () => {
  const aesKey = "ClmT0VGkcrN6MREaX4UxDSwzDo+Ix7/8GPrEkblo/UM="
  const iv = "Ww07DAcYfiyFMw6GyzZpqA=="
  const encryptedPublicKey = "PF2WZw34wc2GD1+h8ugO7+cFJbMInLfLEcxJO9p6jH6RMVhKGfQIbD51SLGBljazlkRf7IwL6WG/1MyAeqXGqE+4CmnpkxZCHmDFv81vo4pA8hZGHTtQBkXcalezo7KyxON3UvmWREckC3RlJsV7PM1qDbylWfuYNPwmQgTbizIZLbCQz09aFR/cLHgHb3+0/qva/Z6hfroJLvfwlHRWCJJSqb2XpgRiBqMSgd67xp5LPxOmxntyjV51e30mxIXy5vbmid9Ovt94QjjqhQivHF7V8Q+hoVkEo0m5+rtiNybTLDpqn13ATGCfl0BxwwK8aCt7m1dK5hOwp2ycmGDBL1VFsIpUhH/O/ac9G8xzgHhp04lvm9h4RgXvp5fuCLdeNGD8BXK8kfHFGXy2WQ0Fmax3PD3GSeMb9ZKH4XDr2c6Uqqtu4giHbv6D3f5R+38TsdybSbyMfpcZMxEt4mCONTuAKAyfAkaC/BYL6eACRrvyo7JHMh9P6xy1dfGMRdKvF6Zh494UpWkSA9NZHpF99Ixb3n3b4il7jxFeYf/k5JvpSUFCOSfFfU+uuFFb4XBXbRVUR3K/Z31gKYYWxTs4hzUKvUPxHm9Bx2kblKS+ASY="

  const randomUUID = () => { return crypto.randomUUID() };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  const decryptPublicKey = async (encryptedKeyBase64, aesKeyBase64, ivBase64) => {
    const aesKeyBuffer = base64ToArrayBuffer(aesKeyBase64);
    const ivBuffer = base64ToArrayBuffer(ivBase64);
    const encryptedKeyBuffer = base64ToArrayBuffer(encryptedKeyBase64);

    const aesKey = await crypto.subtle.importKey(
      'raw',
      aesKeyBuffer,
      { name: 'AES-CBC' },
      false,
      ['decrypt']
    );

    const decryptedBuffer = await crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: ivBuffer },
      aesKey,
      encryptedKeyBuffer
    );

    return new TextDecoder().decode(decryptedBuffer);
  };

  const pemToArrayBuffer = (pem) =>{
    const base64 = pem
      .replace(/-----BEGIN PUBLIC KEY-----/, "")
      .replace(/-----END PUBLIC KEY-----/, "")
      .replace(/\s+/g, '');
    const binary = atob(base64);
    const arrayBuffer = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      arrayBuffer[i] = binary.charCodeAt(i);
    }
    return arrayBuffer.buffer;
  }

  const importPublicKey = async (pem) => {
    const keyData = pemToArrayBuffer(pem);
    return await crypto.subtle.importKey(
      "spki",
      keyData,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, // Match the hash used in decryption
      },
      true,
      ["encrypt"]
    );
  }

  const encryptMessage = async (message) => {
    const pemPublicKey = await decryptPublicKey(encryptedPublicKey, aesKey, iv);
    const publicKey = await importPublicKey(pemPublicKey);
    const encodedMessage = new TextEncoder().encode(message);
    const encryptedBuffer = await crypto.subtle.encrypt(
      {
        name: "RSA-OAEP"
      },
      publicKey,
      encodedMessage
    );

    // Convert encrypted ArrayBuffer to Base64
    return arrayBufferToBase64(encryptedBuffer);
  }

  return {
    encryptMessage,
    randomUUID
  }
}

export default useEncryption