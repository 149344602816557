const useCustomRouter = () => {
  const page = ref('treasure');
  const historyStack = useState<string[]>('historyStack', () => [])
  const customRouterState = useState('CustomRouterState', () => page.value)

  const route = useRoute()

  if (route.path === '/pennywhalestma/admin/withdraw/request/withdrawList') {
    customRouterState.value = 'withdrawalrequest'
  }

  if (route.path === '/pennywhalestma/admin/taskprogress/taskcheck') {
    customRouterState.value = 'taskapproval'
  }
  const navigateToPage = (page: 'treasure' | 'friend' | 'airdrop' | 'earn' | 'mine' | 'booster' | 'monopoly' | 'leaderboard' | 'campaignrule' | 'guessbitcoin' |'guessleaderboard' | 'redotpay' | 'mainguessbtc' | 'shellpackage' | 'taskairdrop' | 'guesscampaignrule') => {
    historyStack.value.push(customRouterState.value)
    // console.log(historyStack.value)
    customRouterState.value = page
  }

  

  const goBack = () => {
    // console.log(historyStack.value)
    if (historyStack.value) {
      customRouterState.value = historyStack.value.pop() || 'treasure'
      // console.log('New state after going back:', customRouterState.value, 'History stack:', historyStack.value)
    } else {
      console.warn('No previous state to go back to')
    }
  }

  return {
    customRouterState,
    navigateToPage,
    goBack
  }
}

export default useCustomRouter