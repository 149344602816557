<script setup lang="ts">
const {
  customRouterState,
  navigateToPage
} = useCustomRouter()

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)


</script>

<template>
  <div
    class="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[calc(100%-2rem)] max-w-lg bg-blue-800/95 flex justify-around items-center z-30 rounded-3xl text-xs h-20">
    <div class="relative text-center text-white w-1/6"
      :class="customRouterState == 'treasure' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('treasure')">
      <img src="/images/treasure.png" alt="Treasure" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Treasure</p>
      <span v-if="authState.accumulatedEnergy >= authState.maximumEnergy"
        class="absolute top-1 right-1 grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white">
      </span>
      
    </div>
    <div class=" relative text-center text-white w-1/6" :class="customRouterState == 'mine' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('mine')">
      <img src="/images/mine.png" alt="Mine" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Mine</p>
      <span v-if="authState.accumulatedPointsMining == authState.maxMiningPoints"
        class="absolute top-1 right-1 grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white">
      </span>
    </div>
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'friend' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('friend')">
      <img src="/images/friends.png" alt="Friends" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Friends</p>
    </div>
    <!-- <div class="text-center text-white w-1/6"
      :class="customRouterState == 'leaderboard' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('leaderboard')">
      <img src="/images/medal_gold.png" alt="Leaderboard" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Rank</p>
    </div> -->
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'mainguessbtc' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('mainguessbtc')">
      <img src="/images/Bitcoin_Icon.png" alt="GuessBTC" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Guess</p>
    </div>
    <div class="relative text-center text-white w-1/6" :class="customRouterState == 'earn' && 'bg-blue-950 m-1 p-2 rounded-2xl'"
      @click="navigateToPage('earn')">
      <img src="/images/earn.png" alt="Earn" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Earn</p>
      <span v-if="authState.taskStatusOverall == 'not completed'"
        class="absolute top-1 right-1 grid min-h-[12px] min-w-[12px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 text-xs text-white">
      </span>
    </div>
    <div class="text-center text-white w-1/6"
      :class="customRouterState == 'airdrop' && 'bg-blue-950 m-1 p-2 rounded-2xl'" @click="navigateToPage('airdrop')">
      <img src="/images/airdrop.png" alt="Airdrop" class="w-8 h-8 mx-auto" />
      <p class="mt-1 text-xs">Wallet</p>
    </div>
  </div>
</template>